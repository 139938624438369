@import '../variables';

.header {
  background-image: linear-gradient(180deg, rgba(#0e0082, .7) 30%, rgba(#715DAD, 1) 50%),
  url('/assets/images/header.jpg');
  width: 100%;

  &__wrapper {
    display: flex;
    position: relative;
    min-height: 1057px;
    overflow: hidden;

    @media (max-width: $mobile) {
      min-height: 500px;
      width: 100%;
    }
  }

  &__content {
    z-index: 2;
    width: 610px;
    margin: 187px 164px;

    @media (max-width: $mobile) {
      margin: 108px 0 0 24px;
      width: 200px;
    }
  }

  &__actions {
    display: grid;
    width: 400px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px 20px;
    margin-top: 32px;

    @media (max-width: $mobile) {
      width: auto;
      grid-gap: 13px 12px;
      margin-top: 23px;
    }
  }

  &__title {
    font-weight: 800;
    font-size: 131px;
    line-height: 154px;
    letter-spacing: 5px;
    font-style: normal;
    margin: 0;
    padding: 0;
    font-family: 'Roboto Bold', sans-serif;

    @media (max-width: $mobile) {
      font-weight: bold;
      font-size: 40px;
      line-height: 47px;
      letter-spacing: 1.65094px;
    }
  }

  &__subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 68px;
    line-height: 80px;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
    font-family: 'Roboto Light', sans-serif;

    @media (max-width: $mobile) {
      font-weight: 300;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.330189px;
      margin: 10px 0;
    }
  }

  &__iphones {
    z-index: 1;
    position: absolute;

    width: 1200px;
    height: 912px;

    background-image: url('/assets/images/iphones-header.png');
    background-size: cover;

    top: 179px;
    right: -35px;
    left: auto;

    @media (max-width: 1270px) {
      width: 370px;
      height: 348px;

      top: 273px;
      right: 94px;
    }

    @media (max-width: $mobile) {
      left: auto;
      top: 221px;
    }


    @media (max-width: 550px) {
      left: 58px;
      top: 221px;
    }
  }
}
